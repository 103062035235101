.notif-config-tab-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0rem;
    isolation: isolate;
    filter: drop-shadow(0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.05));
    border-radius: 0.5rem;
}

.notif-config-tab-text {
    margin: auto;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #26404E;
}

.notif-config-tab-button-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    gap: 1.5rem;
}

.notif-config-tab-users {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.125rem 0.5rem;
    background: #ECFDF3;
    mix-blend-mode: multiply;
    border-radius: 1rem;
    height: 1.375rem;
    width: auto;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: #027A48;
}

.notif-config-tab-users-empty {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.125rem 0.5rem;
    background: #FEF3F2;
    mix-blend-mode: multiply;
    border-radius: 1rem;
    height: 1.375rem;
    width: auto;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    text-align: center;
    color: #B42318;
}

.notif-config-tab-buttons {
    display: flex;
    gap: 1rem;
}

.notif-config-tab-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.0625rem 0.25rem 0.6875rem;
    gap: 0.5rem;
    position: relative;
    right: 0rem;
    top: 0rem;
    border-radius: 0.5rem;
    flex: none;
    order: 1;
    flex-grow: 0;
    z-index: 0;
    cursor: pointer;
}

.notif-config-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.notif-config-tab-button {
    display: inline-block;
    cursor: pointer; 
}
.text-input {
    position: relative;
}

/* Input Title */
.input-title {
    position: absolute;
    top: -0.65rem;
    left: 0.75rem;
    background: white;
    padding: 0 0.25rem;
    font-size: 0.75rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    z-index: 1;
}

.input-title.disabled {
    color: rgba(0, 0, 0, 0.38);
}

/* Input Icon */
.input-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 22px;
    height: 22px;
    pointer-events: none;
    opacity: 1;
}

.input-icon.disabled {
    opacity: 0.6;
}

/* Input Text Field */
.input-text-container {
    width: 100%;
    padding: 10px 0.875rem;
    padding-left: 2.5rem; /* Default padding for icon space */
    gap: 0.5rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1.05rem;
    line-height: 1.5rem;
    color: #101828;
    border-radius: 0.5rem;
    border: 1px solid #D0D5DD;
    background-color: transparent;
    cursor: text;
    outline: none;
    transition: border-color 0.2s ease-in-out;
}

/* If no icon, adjust padding */
.input-text-container:not(:has(+ .input-icon)) {
    padding-left: 0.875rem;
}

/* Hover and Focus Effects */
.input-text-container:focus,
.input-text-container:hover {
    border-color: #667085;
}

/* Disabled Styles */
.input-text-container.disabled {
    color: rgba(0, 0, 0, 0.38);
    cursor: not-allowed;
    border-color: #D0D5DD;
}

.input-text-container.disabled:hover {
    border-color: #D0D5DD;
}
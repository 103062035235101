.notification-history-container {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    gap: 1.5rem;
    height: 100vh;
    overflow: hidden;
}

.notification-history-header {
    padding: 0px 2rem;
    display: flex;
    gap: 24px;
    box-sizing: border-box;
    flex-direction: column;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #ffffff;
}

.notification-history-body {
    display: flex;
    width: 100%;
    height: calc(100vh - 200px);
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 50px;
    position: relative;
}

.notification-pagination-container {
    position: sticky;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    box-sizing: border-box;
    overflow: hidden;
    border-top: 1px solid #EAECF0;
}

.notification-pagination-container button {
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px 15px;
}

.notification-pagination-container button:disabled {
    cursor: not-allowed;
}

.notification-pagination-container span {
    font-size: 1rem;
    color: #365A6D;
    margin: 0 auto;
}

.notification-pagination-container > span {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.notification-pagination-icon {
    width: 100%;
    height: 100%;
    margin-right: 5px;
}

.notification-pagination-container button span {
    font-size: 1rem;
    color: #365A6D;
    display: flex;
    align-items: center;
}

.notification-pagination-container span {
    font-size: 0.875rem;
    color: #365A6D;
    margin: 0 auto;
}

.ListNavigationTab {
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.list-navigator-container {
    padding: 2rem 2rem !important;
}

.no-notifications-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px;
    color: #666;
    width: 100%;
}

.no-notifications-message img {
    width: 150px;
}

.no-notifications-message p {
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    color: #101828;
    font-style: Inter;
}
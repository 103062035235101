.transfer-owner-modal-header {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    width: auto;
    padding: 1.5rem 1.5rem 0rem;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    gap: 1rem;
}

.icon-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.icon-container {
    display: flex;
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    /* Base/White */
    background: #FFFFFF;
    /* Gray/200 */
    border: 1px solid #EAECF0;
    /* Shadow/xs */
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 10px;
}

.x-button-container {
    display: flex;
}

.transfer-owner-x-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 2.75rem;
    height: 2.75rem;
    cursor: pointer;
}

.owner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 24px 0px;
    gap: 6px;
    width: auto;
    margin-right: 25px;
}

.transfer-owner-button-container {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: flex-start;
    padding: 20px 24px;
    width: auto;
}
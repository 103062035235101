.export-alerts-container {
    display: flex;
    width: 100%;
    gap: 1.5rem;
    flex-direction: column;
}


.export-alerts-header {
    padding: 0rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.export-alerts-tabs {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 0.5rem;
    border-bottom: 0.0625rem solid #EAECF0;
    width: 100%;
}

.export-alerts-body {
    padding: 0rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.export-alerts-body-title {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    padding-bottom: 1.25rem;
    border-bottom: 0.0625rem solid #EAECF0;
    padding-top: 1.25rem;
    border-top: 0.0625rem solid #EAECF0;
}

.export-alerts-button-group {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.export-alerts-body-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.timeline-container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding-bottom: 20px;
    border-bottom: 0.0625rem solid #EAECF0;
    width: 100%;
}

.timeline-title-subtitle-container {
    display: flex;
    flex-direction: column;
    width: 17.5rem;
}

.timeline-text {
    width: 17.5rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
}

.timeline-subtitle {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
}

.asset-device-container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding: 20px 0px 24px;
    border-bottom: 0.0625rem solid #EAECF0;
}

.exportAlerts-notification-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.exportAlerts-progress-bar {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.exportAlerts-progress-bar-text {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
